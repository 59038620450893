import classNames from "classnames";
import "./Button.scss";

export interface IButtonProps {
  className?: string;
  onClick?: () => void;
  label: any;
}

const Button = (props: IButtonProps) => {
  const { className, onClick, label } = props;

  const classes = classNames("button", className);
  return (
    <button onClick={onClick} className={classes}>
      {label}
    </button>
  );
};

export default Button;
