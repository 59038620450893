import {
  facebookIcon,
  instagramIcon,
  threadsIcon,
  twitterIcon,
  youtubeIcon,
} from "../../assets/icons";
import { usocLogo } from "../../assets/images";
import Button from "../Button/Button";
import "./Footer.scss";

export interface IFooterProps {
  className?: string | object;
}

const Footer = (props: IFooterProps) => {
  const { className } = props;

  const handleSignUpClick = () => {
    window.open("https://unitedstatesofcare.org/subscribe/", "_blank");
  };

  return (
    <div className="footer">
      <div className="cta">
        <span className="call-to-action">{"Stay up to date! "}</span>
        {"Sign up to receive the latest "}
        <span className="bold">{"news, resources and updates "}</span>
        {"on building a better healthcare system. "}
        <Button
          label={"Sign Up"}
          onClick={handleSignUpClick}
          className="sign-up-button"
        />
      </div>
      <div className="footer-links">
        <a
          href="https://unitedstatesofcare.org"
          target="_blank"
          rel="noreferrer"
          title="United States of Care"
        >
          <img
            src={usocLogo}
            alt="United State of Care"
            className="usoc-logo"
          />
        </a>
        <a
          href="https://www.facebook.com/USofCare/"
          target="_blank"
          rel="noreferrer"
          title="USoC on Facebook"
        >
          <img src={facebookIcon} alt="facebook" className="icon" />
        </a>
        <a
          href="https://twitter.com/USofCare"
          target="_blank"
          rel="noreferrer"
          title="USoC on Twitter"
        >
          <img src={twitterIcon} alt="twitter" className="icon" />
        </a>
        <a
          href="https://www.youtube.com/channel/UC-28S-j0XrPJuPJotlZm4sw"
          target="_blank"
          rel="noreferrer"
          title="USoC on YouTube"
        >
          <img src={youtubeIcon} alt="youtube" className="icon" />
        </a>
        <a
          href="https://www.instagram.com/usofcare/"
          target="_blank"
          rel="noreferrer"
          title="USoC on Instagram"
        >
          <img src={instagramIcon} alt="instagram" className="icon" />
        </a>
        <a
          href="https://www.threads.net/@usofcare"
          target="_blank"
          rel="noreferrer"
          title="USoC on Threads"
        >
          <img src={threadsIcon} alt="threads" className="icon" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
