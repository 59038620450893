import { lazy } from "react";
import { IRouteSetup } from "./Routes";

const Home = lazy(() => import("../home/Home"));
const About = lazy(() => import("../about/About"));
const Resources = lazy(() => import("../resources/Resources"));
const StateAndFederalAction = lazy(
  () => import("../stateAndFederalAction/StateAndFederalAction")
);
const NotFound = lazy(() => import("../../components/NotFound/NotFound"));

export const HomeRoute: IRouteSetup = {
  Component: Home,
  path: "/",
};

export const AboutRoute: IRouteSetup = {
  Component: About,
  path: "/about",
};

export const ResourcesRoute: IRouteSetup = {
  Component: Resources,
  path: "/resources",
};

export const StateAndFederalActionRoute: IRouteSetup = {
  Component: StateAndFederalAction,
  path: "/state-federal-action",
};

export const NotFoundRoute: IRouteSetup = {
  Component: NotFound,
  path: "*",
};

export const routesConfigs: IRouteSetup[] = [
  HomeRoute,
  AboutRoute,
  ResourcesRoute,
  StateAndFederalActionRoute,
  NotFoundRoute,
];
