import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { routesConfigs } from "./RoutesConfigs";

export interface IRouteSetup {
  Component: React.JSXElementConstructor<any>;
  path: string;
}

const AppRoutes = () => {
  return (
    <Suspense>
      <Routes>
        {routesConfigs.map((routeConfig: IRouteSetup) => {
          const { path, Component } = routeConfig;
          return <Route key={path} path={path} element={<Component />} />;
        })}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
