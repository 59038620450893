import { Link } from "react-router-dom";
import { logo } from "../../assets/images";
import NavMenu from "../NavMenu/NavMenu";
import "./Header.scss";

export interface IHeaderProps {
  className?: string | object;
}

const Header = (props: IHeaderProps) => {
  const { className } = props;

  return (
    <div className="header">
      <Link to="/">
        <img src={logo} className="logo" alt="Hidden Cost of Care Logo" />
      </Link>
      <NavMenu />
    </div>
  );
};

export default Header;
