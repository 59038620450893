import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./App.scss";
import AppRoutes from "./Routes";

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <BrowserRouter>
          <Header />
          <div className="main-app-body">
            <AppRoutes />
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
