import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavMenu.scss";

export interface INavMenuProps {
  className?: string | object;
}

const NavMenu = (props: INavMenuProps) => {
  const dropdownRef = useRef<any>();
  const [isActive, setIsActive] = useState(false);

  const handleMenuButtonClick = () => {
    isActive === true ? setIsActive(false) : setIsActive(true);
  };

  const renderButtons = () => (
    <>
      <NavLink
        to={"/"}
        className={(isActive) =>
          isActive.isActive ? "menu-link is-active" : "menu-link"
        }
      >
        {"Home"}
      </NavLink>
      <NavLink
        to={"about"}
        className={(isActive) =>
          isActive.isActive ? "menu-link is-active" : "menu-link"
        }
      >
        {"About"}
      </NavLink>
      <NavLink
        to={"resources"}
        className={(isActive) =>
          isActive.isActive ? "menu-link is-active" : "menu-link"
        }
      >
        {"Resources"}
      </NavLink>
      <NavLink
        to={"state-federal-action"}
        className={(isActive) =>
          isActive.isActive ? "menu-link is-active" : "menu-link"
        }
      >
        {"State & Federal Action"}
      </NavLink>
    </>
  );
  const { className } = props;

  return (
    <>
      <div className="nav-menu">{renderButtons()}</div>
      <div className="mobile-nav">
        <div onClick={handleMenuButtonClick} className="menu-button">
          {"≡"}
        </div>
        <nav
          ref={dropdownRef}
          className={`mobile-menu ${isActive ? "active" : "inactive"}`}
        >
          <span className="close" onClick={() => handleMenuButtonClick()}>
            {"+"}
          </span>
          <div className="mobile-links" onClick={() => setIsActive(false)}>
            {renderButtons()}
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavMenu;
